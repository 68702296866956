<template>
  <section class="dashboard" v-if="dashStats">
    <div class="page-header">
      <h3 class="page-title">
        <!-- <span class="page-title-icon bg-gradient-primary text-white mr-2">
        <i class="mdi mdi-home"></i>
      </span>  -->
        Dashboard
      </h3>
      <nav aria-label="breadcrumb">
        <ul class="breadcrumb">
          <li class="breadcrumb-item active" aria-current="page">
            <span></span>Overview
            <!-- <i class="mdi mdi-alert-circle-outline icon-sm text-primary align-middle"></i> -->
          </li>
        </ul>
      </nav>
    </div>
    <div class="row">
      <div class="col-md-4 stretch-card grid-margin pb-3 mb-3">
        <div class="card bg-gradient-danger card-img-holder text-white">
          <div class="card-body p-4">
            <img src="../../assets/images/circle.svg" class="card-img-absolute" alt="circle-image" />
            <h4 class="font-weight-normal mb-3">Registered Users<i class="mdi mdi-chart-line mdi-24px float-right"></i>
            </h4>
            <h2><b>1234</b></h2>
            <sup class="mt-2">Number of users registered</sup>
          </div>
        </div>
      </div>
      <div class="col-md-4 stretch-card grid-margin pb-3 mb-3">
        <div class="card bg-gradient-info card-img-holder text-white">
          <div class="card-body p-4">
            <img src="../../assets/images/circle.svg" class="card-img-absolute" alt="circle-image" />
            <h4 class="font-weight-normal mb-3">Orders<i class="mdi mdi-bookmark-outline mdi-24px float-right"></i>
            </h4>
            <h2><b>1111</b></h2>
            <sup class="mt-2">Total Number of sales</sup>
          </div>
        </div>
      </div>
      <div class="col-md-4 stretch-card grid-margin pb-3 mb-3">
        <div class="card bg-gradient-success card-img-holder text-white">
          <div class="card-body p-4">
            <img src="../../assets/images/circle.svg" class="card-img-absolute" alt="circle-image" />
            <h4 class="font-weight-normal mb-3">Products <i class="mdi mdi-diamond mdi-24px float-right"></i>
            </h4>
            <h2><b>1511</b></h2>
            <sup class="mt-2"> Available products</sup>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-title">Recent Orders </div>
          <div class="table-responsive mb-0">
            <table class="table table-centered table-nowrap">
              <thead>
                <tr>
                  <th>Order No</th>
                  <th>Customer Name</th>
                  <th>Total Items</th>
                  <th>Total Values</th>
                  <th>Date </th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>#ON12345</td>
                  <td>Text User</td>
                  <td>10</td>
                  <td>150</td>
                  <td>11/12/2025</td>
                  <td> <span class="badge badge-gradient-danger">Pending &nbsp;&nbsp;&nbsp; Cash on Delivery</span></td>
                </tr>
                <tr>
                  <td>#ON12345</td>
                  <td>Text User</td>
                  <td>10</td>
                  <td>150</td>
                  <td>11/10/2025</td>
                  <td> <span class="badge badge-gradient-success">Successful &nbsp;&nbsp;&nbsp; Credit Card</span></td>
                </tr>
              </tbody>
            </table>
          </div>

        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6  pb-3 mt-4">
        <div class="card  card-img-holder text-white"  style="border-radius: 0px;">
          <div class="card-title">
            <h4>Sales Analytics - Past 6 Months</h4>
          </div>
          <div class="card-body p-0">
            <visitAndSalesStatitics class='mt-2' :height='184'></visitAndSalesStatitics>
          </div>
        </div>
      </div>
      <div class="col-md-6    pb-3 mt-4">
        <div class="row  pb-3">
          <div class="col-md-6   pl-3  p-0">
            <div class="card p-3 h-100" style="border-radius: 0px;">
              <div class="text-section">
                <h3 class="title green">Popular</h3>
                <p class="subtitle orange">Product of Al Ghandi</p>
                <p class="info">Total Times Purchased : 179</p>
              </div>
            </div>

          </div>
          <div class="col-md-6  p-0 pr-3">
            <div class="card logo-section orange h-100">
              <div class="text-center">
                <div class="logo text-white">Philips</div>
                <p class="brand text-white">Carbon</p>
              </div>
            </div>

          </div>
        </div>
        <div class="row  pb-3">
        
          <div class="col-md-6  p-0 pl-3">
            <div class="card  logo-section green-bg h-100">
              <div class="text-section ">
                <h3 class="title">Popular User</h3>
              </div>
            </div>

          </div>
          <div class="col-md-6   pr-3  p-0">
            <div class="card p-3 h-100" style="border-radius: 0px;">
              <div class="info-section text-center">
                <h4 class="orange">Administrator Al ghandi</h4>
                <p class="green">54 items purchased</p>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
    <!-- <div class="row mb-3 pb-3" v-if="dashStats">
      <div class="col-xl-3 col-lg-3 col-md-6 col-sm-6 grid-margin stretch-card">
        <div class="card card-statistics">
          <div class="card-body">
            <div class="clearfix">
              <div class="float-left">
                <p class="mb-1  font-weight-500 ">Total Orders</p>
                <div class="fluid-container">
                  <h3 class="font-weight-medium mb-0 font-weight-600 ">{{ dashStats.orders.all }}</h3>
                </div>
              </div>
              <div class="float-right">
                <i class="mdi mdi-receipt text-warning icon-lg"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-xl-3 col-lg-3 col-md-6 col-sm-6 grid-margin stretch-card">
        <div class="card card-statistics">
          <div class="card-body">
            <div class="clearfix">
              <div class="float-left">
                <p class="mb-1 font-weight-500">Pending Orders</p>
                <div class="fluid-container">
                  <h3 class="font-weight-medium mb-0 font-weight-600 ">
                    {{ dashStats.orders.pending_orders }}
                  </h3>
                </div>
              </div>
              <div class="float-right">
                <i class="mdi mdi-poll-box text-success icon-lg"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-xl-3 col-lg-3 col-md-6 col-sm-6 grid-margin stretch-card">
        <div class="card card-statistics">
          <div class="card-body">
            <div class="clearfix">
              <div class="float-left">
                <p class="mb-1 font-weight-500">Shipped Orders</p>
                <div class="fluid-container">
                  <h3 class="font-weight-medium mb-0 font-weight-600">
                    {{ dashStats.orders.shipped_orders }}
                  </h3>
                </div>
              </div>
              <div class="float-right">
                <i class="mdi mdi-cube text-danger icon-lg"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-xl-3 col-lg-3 col-md-6 col-sm-6 grid-margin stretch-card">
        <div class="card card-statistics">
          <div class="card-body">
            <div class="clearfix">
              <div class="float-left">
                <p class="mb-1 font-weight-500">Delivered Orders</p>
                <div class="fluid-container">
                  <h3 class="font-weight-medium mb-0 font-weight-600">
                    {{ dashStats.orders.seccessful_orders }}
                  </h3>
                </div>
              </div>
              <div class="float-right">
                <i class="mdi mdi-checkbox-marked-circle-outline text-info icon-lg"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
      
    </div>
    <div class="row">
      <div class=" col-md-12 grid-margin stretch-card mb-3">
        <div class="card">
          <h4 class="card-title">Order By Days</h4>
          <div class="card-body px-2 py-3">
            <orderbyweekChart :height="150"></orderbyweekChart>
          </div>
        </div>
      </div>
      <div class="col-lg-6 col-md-6 grid-margin stretch-card mb-3">
        <div class="card">
          <h4 class="card-title">Order Status</h4>
          <div class="card-body">
            <orderStatusChart :height="250"></orderStatusChart>
          </div>
        </div>
      </div>
      <div class="col-lg-6 col-md-6 grid-margin stretch-card mb-3">
        <div class="card">
          <h4 class="card-title">User Status</h4>
          <div class="card-body">
            <userStatusChart :height="250"></userStatusChart>
          </div>
        </div>
      </div>
    </div> -->
  </section>
</template>

<script>
import visitAndSalesStatitics from '../../components/visitAndSalesStatitics'
import orderStatusChart from "../../components/orderStatusChart";
import userStatusChart from "../../components/userStatusChart";
import orderbyweekChart from "../../components/orderbyweekChart";
import { dashboardApi } from "../../api";

export default {
  name: "dashboard",
  components: {
    orderStatusChart,
    orderbyweekChart, visitAndSalesStatitics,
    userStatusChart
  },
  data() {
    return {
      dashStats: "",
      
    };
  },
  mounted() {
    this.stats();
  },
  methods: {
    async stats() {
      
      const { data } = await dashboardApi.stats();
      this.dashStats = data.data.data;
      
    },
  },
};
</script>

<style scoped>
.mdi-24px.mdi:before {
  font-size: 44px;
}


.text-section {
  flex: 1;
}

.logo-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  border-radius: 0px;
  color: white;
  font-size: 1.5rem;
  font-weight: bold;
}

.green-bg {
  background-color: #8bc34a;
  color: white;
  padding: 20px;
  border-radius: 0px;
}

.title {
  font-size: 1.5rem;
  font-weight: bold;
}

.subtitle {
  font-size: 14px;
  color: #ff5722;
}

.info {
  font-size: 14px;
  color: #555;
}

.orange {
  color: #ff5722;
}

.green {
  color: #8bc34a;
}

.logo-section.orange {
  background-color: #ff5722;
}

.brand {
  font-size: 1.2rem;
}
</style>
