<script>
import {
  Doughnut
} from 'vue-chartjs'
import { dashboardApi } from "../api";

export default {
  extends: Doughnut,
    components: {
    
  },
  data () {
    return {
      data: "",
      
      datacollection: {
        labels: [
          'Pending Orders',
          'Delivered Orders',
          'Shipped Orders',
        ],
        datasets: [{
          data: [],
          backgroundColor: [
            'rgba(255, 99, 132, 0.5)',
            'rgba(54, 162, 235, 0.5)',
            'rgba(75, 192, 192, 0.5)',
            'rgba(153, 102, 255, 0.5)',
            'rgba(255, 206, 86, 0.5)',
            
            'rgba(255, 159, 64, 0.5)'
          ],
          borderColor: [
            'rgba(255,99,132,1)',
            'rgba(54, 162, 235, 1)',
            'rgba(75, 192, 192, 1)',
            'rgba(255, 206, 86, 1)',
            'rgba(153, 102, 255, 1)',
            'rgba(255, 159, 64, 1)'
          ]
        }]
      },
      options: {
        responsive: true,
        animation: {
          animateScale: true,
          animateRotate: true
        },
        legend: false
      }
    }
  },
  mounted() {
    this.orderByStatus();
  },
  methods: {
    async orderByStatus() {
      (this.datacollection.datasets[0].data = []);
      
      const { data } = await dashboardApi.stats();
      this.data = data.data.data.orders;
      this.datacollection.datasets[0].data.push(this.data.pending_orders,this.data.seccessful_orders,this.data.shipped_orders);
      
      this.renderChart(this.datacollection, this.options)
    },
  },
}
</script>
